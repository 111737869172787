import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import './mainTitle.scss'

const MainTitle = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    return(
        <Fade bottom cascade>
            <div className="main-title">
                <div className="main-title__container">
                    <div className="main-title__column">
                        <div className="main-title__content"
                            dangerouslySetInnerHTML={createMarkup()} />

                        { props.linkUrl &&
                            <div className="main-title__button">
                                <Link to={ props.linkUrl }>{ props.linkText }</Link>
                            </div>                        
                        } 
                    </div>
                </div>
            </div>
        </Fade>
    )
}

MainTitle.propTypes = {
    content: propTypes.string,
    linkText: propTypes.string,
    linkUrl: propTypes.string
}

export default MainTitle
